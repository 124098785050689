/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EFormType = "EUC0" | "EUC1" | "G0517" | "HSC0" | "HSC1" | "HSC2" | "HSG0" | "HSSL0" | "HSSL1" | "LPABC0" | "LPABC1" | "LPAC0" | "LPAC1" | "LPAG0" | "LPAG2" | "LPAG3" | "LPAHRG0" | "LPASL0" | "LPASL1" | "MSAC0" | "MSAC1" | "NFASC0" | "NFASDDC0" | "NFASDDC1" | "NFASEUC0" | "NFASEUC1" | "%future added value";
export type TemplateListFragment_query = {
    readonly templates: {
        readonly pageInfo: {
            readonly hasNextPage: boolean;
            readonly endCursor: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly cursor: string;
            readonly node: {
                readonly id: string;
                readonly updatedAt: unknown | null;
                readonly forms: ReadonlyArray<{
                    readonly type: EFormType | null;
                } | null> | null;
                readonly " $fragmentRefs": FragmentRefs<"TemplateRow_template">;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "TemplateListFragment_query";
};
export type TemplateListFragment_query$data = TemplateListFragment_query;
export type TemplateListFragment_query$key = {
    readonly " $data"?: TemplateListFragment_query$data;
    readonly " $fragmentRefs": FragmentRefs<"TemplateListFragment_query">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "templates"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "envdAccountId"
    },
    {
      "kind": "RootArgument",
      "name": "searchText"
    },
    {
      "kind": "RootArgument",
      "name": "species"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./TemplateListRefetchableQuery.graphql.ts')
    }
  },
  "name": "TemplateListFragment_query",
  "selections": [
    {
      "alias": "templates",
      "args": [
        {
          "kind": "Variable",
          "name": "envdAccountId",
          "variableName": "envdAccountId"
        },
        {
          "kind": "Variable",
          "name": "searchText",
          "variableName": "searchText"
        },
        {
          "kind": "Variable",
          "name": "species",
          "variableName": "species"
        }
      ],
      "concreteType": "TemplateConnection",
      "kind": "LinkedField",
      "name": "__TemplateListFragment_templates_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TemplateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Template",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FormType",
                  "kind": "LinkedField",
                  "name": "forms",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TemplateRow_template"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '8c3d4dd33959805642826ed8700e1901';
export default node;
