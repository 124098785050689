import * as React from 'react';
const SvgTxtFile = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={28} fill="none" {...props}>
        <path fill="#F4F4F4" stroke="#767676" strokeWidth={0.1} d="M2.359 27.878V.05H17.51l5.605 5.843v21.985z" />
        <path fill="#F4F4F4" stroke="#767676" strokeWidth={0.1} d="M23.047 5.823h-5.466V.124z" />
        <path fill="#FAFAFA" d="M1.234 2.449h12.12v4.758H1.234z" />
        <path fill="#AB2FD5" fillRule="evenodd" d="M2.491 2.012 0 2v5.713l2.42 2.49v-2.48h11.63V2.002H2.738z" clipRule="evenodd" />
        <path
            fill="#FAFAFA"
            d="M11.462 7.14h-.878V3.73H9.459V3h3.127v.73h-1.124zM9.227 7.14H8.225l-.963-1.566-.963 1.566h-.94l1.373-2.135L5.446 3h.969l.892 1.49L8.182 3h.946l-1.3 2.053zM4.002 7.14h-.878V3.73H2V3h3.127v.73H4.002z"
        />
        <path
            fill="#AB2FD5"
            fillRule="evenodd"
            d="M5 15.5c0-.276.24-.5.534-.5h14.432c.295 0 .534.224.534.5s-.24.5-.535.5H5.535C5.238 16 5 15.776 5 15.5M5 18c0-.276.24-.5.534-.5h14.432c.295 0 .534.224.534.5s-.24.5-.535.5H5.535C5.238 18.5 5 18.276 5 18m0 2.5c0-.276.24-.5.534-.5h14.432c.295 0 .534.224.534.5s-.24.5-.535.5H5.535C5.238 21 5 20.776 5 20.5"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgTxtFile;
