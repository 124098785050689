/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConsignmentSortFieldEnum = "CREATED_AT_DESC" | "MOVEMENT_DATE_DESC" | "UPDATED_AT_DESC" | "%future added value";
export type ConsignmentSpecies = "BOBBY_CALVES" | "CATTLE" | "GOAT" | "SHEEP_LAMB" | "%future added value";
export type ConsignmentStatus = "DELETED" | "DRAFT" | "LOCKED" | "SUBMITTED" | "%future added value";
export type ConsignmentListFragmentPagedQueryVariables = {
    envdAccountId: string;
    count: number;
    cursor?: string | null;
    searchText?: string | null;
    species?: ConsignmentSpecies | null;
    status?: ConsignmentStatus | null;
    fromDate?: unknown | null;
    toDate?: unknown | null;
    sortField?: ConsignmentSortFieldEnum | null;
};
export type ConsignmentListFragmentPagedQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ConsignmentListFragment_query">;
};
export type ConsignmentListFragmentPagedQuery = {
    readonly response: ConsignmentListFragmentPagedQueryResponse;
    readonly variables: ConsignmentListFragmentPagedQueryVariables;
};



/*
query ConsignmentListFragmentPagedQuery(
  $envdAccountId: String!
  $count: Int!
  $cursor: String
  $searchText: String
  $species: ConsignmentSpecies
  $status: ConsignmentStatus
  $fromDate: DateTime
  $toDate: DateTime
  $sortField: ConsignmentSortFieldEnum
) {
  ...ConsignmentListFragment_query
}

fragment ConsignmentListFragment_query on Query {
  consignments(envdAccountId: $envdAccountId, first: $count, after: $cursor, searchText: $searchText, species: $species, status: $status, fromDate: $fromDate, toDate: $toDate, sortField: $sortField) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        ...ConsignmentRow_consignment
        updatedAt
        forms {
          type
        }
        __typename
      }
    }
  }
}

fragment ConsignmentRow_consignment on ConsignmentType {
  id
  number
  status
  movementDate
  movementTime
  species
  createdBy
  updatedAt
  createdAt
  pdfUrl
  heads
  buyerPic
  buyerNumOfHeads
  movementID
  movementStatus
  numOfAddedDevices
  forms {
    type
    serialNumber
  }
  owner {
    address {
      line1
      postcode
      state
      town
    }
    name
    pic
  }
  origin {
    address {
      line1
      postcode
      state
      town
    }
    name
    pic
  }
  destination {
    address {
      line1
      postcode
      state
      town
    }
    name
    pic
  }
  consignee {
    address {
      line1
      postcode
      state
      town
    }
    name
    pic
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "envdAccountId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fromDate"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchText"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortField"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "species"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toDate"
},
v9 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "envdAccountId",
    "variableName": "envdAccountId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "fromDate",
    "variableName": "fromDate"
  },
  {
    "kind": "Variable",
    "name": "searchText",
    "variableName": "searchText"
  },
  {
    "kind": "Variable",
    "name": "sortField",
    "variableName": "sortField"
  },
  {
    "kind": "Variable",
    "name": "species",
    "variableName": "species"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  },
  {
    "kind": "Variable",
    "name": "toDate",
    "variableName": "toDate"
  }
],
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "line1",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "postcode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "state",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "town",
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pic",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConsignmentListFragmentPagedQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ConsignmentListFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/),
      (v8/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "ConsignmentListFragmentPagedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "ConsignmentConnection",
        "kind": "LinkedField",
        "name": "consignments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ConsignmentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ConsignmentType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "movementDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "movementTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "species",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdBy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pdfUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "heads",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "buyerPic",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "buyerNumOfHeads",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "movementID",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "movementStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numOfAddedDevices",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FormType",
                    "kind": "LinkedField",
                    "name": "forms",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "serialNumber",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConsignmentOwner",
                    "kind": "LinkedField",
                    "name": "owner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ConsignmentownerAddress",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConsignmentOrigin",
                    "kind": "LinkedField",
                    "name": "origin",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ConsignmentconsignorAddress",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConsignmentDestination",
                    "kind": "LinkedField",
                    "name": "destination",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ConsignmentdestinationAddress",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConsignmentConsignee",
                    "kind": "LinkedField",
                    "name": "consignee",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ConsignmentconsigneeAddress",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "filters": [
          "envdAccountId",
          "searchText",
          "species",
          "status",
          "fromDate",
          "toDate",
          "sortField"
        ],
        "handle": "connection",
        "key": "ConsignmentListFragment_consignments",
        "kind": "LinkedHandle",
        "name": "consignments"
      }
    ]
  },
  "params": {
    "cacheID": "820b541bd6d7d95d07dc5d1ad3c5a931",
    "id": null,
    "metadata": {},
    "name": "ConsignmentListFragmentPagedQuery",
    "operationKind": "query",
    "text": "query ConsignmentListFragmentPagedQuery(\n  $envdAccountId: String!\n  $count: Int!\n  $cursor: String\n  $searchText: String\n  $species: ConsignmentSpecies\n  $status: ConsignmentStatus\n  $fromDate: DateTime\n  $toDate: DateTime\n  $sortField: ConsignmentSortFieldEnum\n) {\n  ...ConsignmentListFragment_query\n}\n\nfragment ConsignmentListFragment_query on Query {\n  consignments(envdAccountId: $envdAccountId, first: $count, after: $cursor, searchText: $searchText, species: $species, status: $status, fromDate: $fromDate, toDate: $toDate, sortField: $sortField) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      cursor\n      node {\n        id\n        ...ConsignmentRow_consignment\n        updatedAt\n        forms {\n          type\n        }\n        __typename\n      }\n    }\n  }\n}\n\nfragment ConsignmentRow_consignment on ConsignmentType {\n  id\n  number\n  status\n  movementDate\n  movementTime\n  species\n  createdBy\n  updatedAt\n  createdAt\n  pdfUrl\n  heads\n  buyerPic\n  buyerNumOfHeads\n  movementID\n  movementStatus\n  numOfAddedDevices\n  forms {\n    type\n    serialNumber\n  }\n  owner {\n    address {\n      line1\n      postcode\n      state\n      town\n    }\n    name\n    pic\n  }\n  origin {\n    address {\n      line1\n      postcode\n      state\n      town\n    }\n    name\n    pic\n  }\n  destination {\n    address {\n      line1\n      postcode\n      state\n      town\n    }\n    name\n    pic\n  }\n  consignee {\n    address {\n      line1\n      postcode\n      state\n      town\n    }\n    name\n    pic\n  }\n}\n"
  }
};
})();
(node as any).hash = '353993065ef615f34e229473e6a8993e';
export default node;
