
import React from "react";

const DeviceTransferNotReadyIcon = (props) => {
  return (
    <svg
    {...props}
      width={24}
      height={24}
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path
      d="M8.59594 1.5387L7.77594 0.718698C7.52927 0.472031 7.18927 0.332031 6.83594 0.332031H6.16927V1.66536H6.83594L7.65594 2.48536L8.59594 1.5387ZM8.1226 2.95203L9.06927 1.9987L10.4826 3.42536L9.53594 4.36536L8.1226 2.95203ZM2.16927 6.33203L2.98927 7.15203L2.0426 8.09203L1.22927 7.2787C1.1026 7.15203 1.0026 6.9987 0.935938 6.84536C0.869271 6.68536 0.835938 6.50536 0.835938 6.33203V5.66536H2.16927V6.33203ZM2.5026 8.56536L3.45594 7.6187L4.86927 9.03203L3.92927 9.9787L2.5026 8.56536ZM5.81594 11.8654L4.4026 10.452L5.3426 9.50536L6.75594 10.9187L5.81594 11.8654ZM10.0093 4.8387L11.4226 6.25203L12.3693 5.31203L10.9559 3.8987L10.0093 4.8387ZM11.8959 6.72536L12.8359 5.7787L13.7759 6.7187C13.9026 6.84536 14.0026 6.9987 14.0693 7.15203C14.1359 7.33203 14.1693 7.49203 14.1693 7.66536C14.1693 8.0187 14.0293 8.3587 13.7759 8.60536L11.8959 6.72536ZM13.3026 9.0787L12.3626 8.1387L10.7359 9.76536L11.6759 10.7054L13.3026 9.0787ZM10.2693 10.232L11.2093 11.172L9.5826 12.7987L8.6426 11.8587L10.2693 10.232ZM9.10927 13.272L7.22927 11.392L6.2826 12.332L7.22927 13.2787C7.4826 13.5254 7.81594 13.6654 8.16927 13.6654C8.5226 13.6654 8.8626 13.5254 9.10927 13.272ZM2.16927 4.9987H0.835938V2.9987H2.16927V4.9987ZM0.835938 2.33203H2.16927V1.66536H2.83594V0.332031H2.16927C1.81594 0.332031 1.47594 0.472031 1.22927 0.725365C0.975937 0.972031 0.835938 1.31203 0.835938 1.66536V2.33203ZM3.5026 1.66536V0.332031H5.5026V1.66536H3.5026ZM3.83594 4.33203C4.38927 4.33203 4.83594 3.88536 4.83594 3.33203C4.83594 2.7787 4.38927 2.33203 3.83594 2.33203C3.2826 2.33203 2.83594 2.7787 2.83594 3.33203C2.83594 3.88536 3.2826 4.33203 3.83594 4.33203Z"
      fill="#848A94"
    />
    </svg>
  );
};

export default DeviceTransferNotReadyIcon;
