import classnames from 'classnames';
import React from 'react';

//#region Keep for arrow
/*

                .ArrowBase {
                    border: solid transparent;
                    content: ' ';
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }

                .ArrowBaseAfter {
                    border-color: rgba(255, 255, 255, 0);
                    border-bottom-color: $color-white;
                    border-width: 8px;

                    @media (prefers-color-scheme: dark) {
                        border-bottom-color: $color-secondary;
                    }
                }

                .ArrowBaseBefore {
                    border-color: rgba(0, 0, 0, 0);
                    border-bottom-color: $color-secondary;
                    border-width: 11px;
                    @media (prefers-color-scheme: dark) {
                        border-bottom-color: $color-white;
                    }
                }

                &:after,
                &:before {
                    bottom: 100%;
                    left: 20%;
                    @extends .ArrowBase;
                }

                &:after {
                    margin-left: -30px;

                    @extends .ArrowBaseAfter;
                }

                &:before {
                    margin-left: -33px;

                    @extends .ArrowBaseBefore;
                }
*/
//#endregion

export interface PopoverProps {
    size?: 'small' | 'medium' | 'large';
    header?: string | React.ReactNode;
    footer?: React.ReactNode;
    popOverContentClassname?: string;
    children?: React.ReactNode;
}

const Popover: React.FC<PopoverProps> = ({ children, size = 'large', header, footer, popOverContentClassname = 'Popover--Content' }) => (
    <div className={classnames('Popover')}>
        <style jsx>{`
            @import 'vars';
            @import 'utils';
            @import 'mixins';

            .Popover {
                position: relative;
                background: $color-white;
                border: 2px solid $color-secondary;
                margin-top: -3px;
                border-radius: 4px;
                padding: grid(4);

                z-index: 99;

                @include box-shadow-sm();

                @media (prefers-color-scheme: dark) {
                    background: $color-black;
                    color: $color-white;
                    border: 2px solid $color-white;
                }

                &--Content {
                    overflow-y: scroll;
                    overflow-x: hidden;
                    height: 100%;
                    width: grid(100);
                    max-height: grid(100);

                    /* Hide Scrollbar */
                    scrollbar-width: none; /* Firefox */
                    -ms-overflow-style: none; /* IE 10+ */
                    &::-webkit-scrollbar {
                        /* Webkit */
                        width: 0;
                        height: 0;
                    }

                    &--Small {
                        width: grid(20); // 80px
                    }

                    &--Medium {
                        width: grid(50); // 200px
                    }

                    &--Large {
                        width: grid(100); // 400px
                        @media (max-width: $sm-max) {
                            width: 100%;
                        }
                    }

                    h3 {
                        margin-bottom: grid(3);
                    }
                }

                .Popover--Footer {
                    padding: grid(2) 0;
                    margin-top: grid(2);
                }

                :global(.nice-dates-popover.-open) {
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
            }
        `}</style>
        <div
            className={classnames(popOverContentClassname, { 'Popover--Content--Small': size === 'small', 'Popover--Content--Medium': size === 'medium', 'Popover--Content--Large': size === 'large' })}
        >
            {header ? typeof header === 'string' && <h3>{header}</h3> : <>{header}</>}
            {children}
            <div className="Popover--Footer">{footer}</div>
        </div>
    </div>
);

export default Popover;
