import { InfoIcon } from '@assets/icons';
import DragDropUpload from '@components/DragNDropUpload';
import Tooltip from '@components/Form/Tooltip';
import React from 'react';

import ManualDeviceInput from './ManualDeviceInput';

interface DeviceUploadProps {
    activeTab: number;
}
const UploadDevice: React.FunctionComponent<DeviceUploadProps> = ({ activeTab }) => {
    const DeviceExamples = () => (
        <>
            <style jsx>
                {`
                    @import 'vars';
                    @import 'utils';
                    .example-container {
                        width: 30%;
                        min-width: 230px;
                        .header {
                            background-color: $unselected-tab-background;
                            padding: 5px 10px;
                            width: 100%;
                            h4 {
                                font-weight: 600;
                            }
                        }
                        .infoIcon {
                            color: $upload-file;
                        }
                    }
                `}
            </style>

            <div className="flex-start-column example-container">
                <div className="header">
                    <h4>Tags examples</h4>
                </div>
                <div className="flex-center-row flex-between m-v-12 p-h-12" style={{ width: '100%' }}>
                    <p className="small">NLISID: 3ABCD123XBK00611</p>
                    <div className="infoIcon">
                        <Tooltip
                            title="What is NLISID?"
                            text="The NLISID is printed on the outside of the electronic ear tag, or on the outside of the visual ear tag indicating that a rumen bolus has been applied. You can read the NLISID number visually and write it down to refer to later."
                            children={<InfoIcon />}
                        />
                    </div>
                </div>
                <div className="flex-center-row flex-between p-h-12" style={{ width: '100%' }}>
                    <p className="small">RFID: 900 012345678993</p>
                    <div className="infoIcon">
                        <Tooltip
                            title="What is RFID?"
                            text="The RFID microchip inside the device is read by a hand-held scanner or panel reader. The device type (breeder or post breeder, ear tag or bolus, cattle device etc.) can be derived from the 10th character of the NLISID."
                            children={<InfoIcon />}
                        />
                    </div>
                </div>
            </div>
        </>
    );
    return (
        <>
            <style jsx>
                {`
                    @import 'vars';
                    @import 'utils';

                    .iconss {
                        color: $upload-file;
                    }
                    .upload-container {
                        width: 70%;
                        min-width: 300px;
                    }
                `}
            </style>
            <div>
                <h2>{activeTab === 0 ? 'Upload file with the NLISID or RFIDs' : 'Please type or paste NLISID or RFIDs into the box below'}</h2>
                <div className="m-v-12">
                    {activeTab === 0 ? (
                        <>
                            <p>Upload a file with devices, such as NLISIDs or RFIDs ear tags, to pre-fill the details.</p>{' '}
                            <p>Ensure the file is in CSV or TXT format, with all numbers organised in a column format.</p>{' '}
                            <p className="m-v-20">Proceeding to the next step will submit all the details.</p>
                        </>
                    ) : (
                        `You can add tags one by one or paste multiple numbers at once. Add more as needed, or click 'Remove All' to start over. Proceeding to the next step will submit all the details.`
                    )}
                </div>
                {activeTab === 0 ? <DragDropUpload children={<DeviceExamples />} /> : <ManualDeviceInput children={<DeviceExamples />} />}
            </div>
        </>
    );
};

export default UploadDevice;
