

import React from "react";

const DeviceTransferTransferredIcon = (props) => {
  return (
    <svg
    {...props}
      width={24}
      height={24}
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2759 6.7187L7.27594 0.718698C7.02927 0.472031 6.68927 0.332031 6.33594 0.332031H1.66927C0.935937 0.332031 0.335938 0.932031 0.335938 1.66536V6.33203C0.335938 6.68536 0.475937 7.02536 0.729271 7.27203L1.0026 7.5387C1.6026 7.1787 2.29594 6.9987 3.0026 6.9987C5.20927 6.9987 7.0026 8.79203 7.0026 10.9987C7.0026 11.7054 6.81594 12.392 6.45594 12.9987L6.7226 13.2654C6.96927 13.5187 7.31594 13.6654 7.66927 13.6654C8.0226 13.6654 8.3626 13.5254 8.60927 13.272L13.2759 8.60536C13.5293 8.3587 13.6693 8.0187 13.6693 7.66536C13.6693 7.31203 13.5293 6.97203 13.2759 6.7187ZM2.66927 3.66536C2.11594 3.66536 1.66927 3.2187 1.66927 2.66536C1.66927 2.11203 2.11594 1.66536 2.66927 1.66536C3.2226 1.66536 3.66927 2.11203 3.66927 2.66536C3.66927 3.2187 3.2226 3.66536 2.66927 3.66536ZM4.75594 8.51203L2.17594 11.112L1.27594 10.1987L0.335938 11.1454L2.16927 12.9987L5.68927 9.45203L4.75594 8.51203Z"
        fill="#333ADD"
      />
    </svg>
  );
};

export default DeviceTransferTransferredIcon;
