import { useFileContext } from '@common/context/DevicesFileContext';
import { handleFileRead } from '@utils/file-ops';
import { createRef, useCallback, useState } from 'react';
import { DropzoneRef, ErrorCode, FileRejection, FileWithPath } from 'react-dropzone';

/**
 * Custom hook to manage files from react-dropzone
 * @returns {Object} An object containing the files, dropzoneProps, and utility functions
 */
const useFileManager = () => {
    const { acceptedFile, setAcceptedFile } = useFileContext();
    const [parsedValues, setParsedValues] = useState<string[]>([]);
    const [fileError, setFileError] = useState<string | null>(null);
    const [inDropZone, setInDropZone] = useState(false);

    const dropzoneRef = createRef<DropzoneRef>();

    const openDialog = () => {
        if (dropzoneRef.current && acceptedFile.length === 0) {
            dropzoneRef.current.open();
        }
    };

    const onDropAccepted = () => setInDropZone(false);
    const onDragOver = () => setInDropZone(true);
    const onDragLeave = () => setInDropZone(false);

    const processFile = (acceptedFiles: FileWithPath[]) => {
        console.log('process files: ', acceptedFiles);
        if (acceptedFiles && acceptedFiles.length > 0) {
            setAcceptedFile(acceptedFiles);
            handleFileRead(
                acceptedFiles[0], // Pass the first file
                (values) => {
                    setParsedValues(values); // Update state with the values
                    setFileError(null); // Clear any previous errors
                },
                (err) => {
                    setFileError(err); // Handle any errors
                }
            );
            console.log('parsed value: ', parsedValues);
        } else {
            setFileError('No file selected.');
        }
    };

    const getInvalidFileErrorMsg = (errorCode: string) => {
        switch (errorCode) {
            case ErrorCode.FileInvalidType:
                return `Unsupported file format. Please use a .csv or .txt file instead.`;
            case ErrorCode.FileTooLarge:
                return `File is larger than 1MB`;
            default:
                return `File Upload failed, Please try again.`;
        }
    };

    const onDropRejected = (fileRejections: FileRejection[]) => {
        setInDropZone(false);
        if (fileRejections.length) {
            const { errors } = fileRejections[0];
            const errMsg = getInvalidFileErrorMsg(errors[0].code);
            setFileError(errMsg);
        }
    };
    // Utility function to clear all files
    const clearFiles = () => {
        setAcceptedFile([]);
    };

    // Cleanup when component unmounts
    useCallback(() => {
        return () => clearFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        dropzoneRef,
        openDialog,
        acceptedFile,
        fileError,
        inDropZone,
        onDropRejected,
        onDropAccepted,
        onDragOver,
        onDragLeave,
        processFile,
        clearFiles,
    };
};

export default useFileManager;
