
import * as React from 'react';

const DeviceTransferReadyIcon = (props) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.2759 6.7187L7.27594 0.718698C7.02927 0.472031 6.68927 0.332031 6.33594 0.332031H1.66927C1.31565 0.332031 0.97651 0.472507 0.726462 0.722555C0.476413 0.972604 0.335938 1.31174 0.335938 1.66536V6.33203C0.335938 6.68536 0.475937 7.02536 0.729271 7.27203L1.0026 7.5387C1.6026 7.1787 2.29594 6.9987 3.0026 6.9987C4.06347 6.9987 5.08089 7.42013 5.83103 8.17027C6.58118 8.92042 7.0026 9.93783 7.0026 10.9987C7.0026 11.7054 6.81594 12.392 6.45594 12.9987L6.7226 13.2654C6.96927 13.5187 7.31594 13.6654 7.66927 13.6654C8.0226 13.6654 8.3626 13.5254 8.60927 13.272L13.2759 8.60536C13.5293 8.3587 13.6693 8.0187 13.6693 7.66536C13.6693 7.31203 13.5293 6.97203 13.2759 6.7187ZM2.66927 3.66536C2.40405 3.66536 2.1497 3.56001 1.96216 3.37247C1.77463 3.18493 1.66927 2.93058 1.66927 2.66536C1.66927 2.40015 1.77463 2.14579 1.96216 1.95826C2.1497 1.77072 2.40405 1.66536 2.66927 1.66536C2.93449 1.66536 3.18884 1.77072 3.37638 1.95826C3.56391 2.14579 3.66927 2.40015 3.66927 2.66536C3.66927 2.93058 3.56391 3.18493 3.37638 3.37247C3.18884 3.56001 2.93449 3.66536 2.66927 3.66536ZM5.66927 11.6654H3.66927V13.6654H2.33594V11.6654H0.335938V10.332H2.33594V8.33203H3.66927V10.332H5.66927V11.6654Z"
        fill="#20A273"
      />
    </svg>
  );
};

export default DeviceTransferReadyIcon;
