import Config from '@config';
import useRoleValidation from '@effects/useRoleValidation';
import { actionTypes, useGlobalDispatch } from '@state';
import { IUser } from '@typings';
import { ConsignmentStatus } from '@utils/enum-transformers';
import { NlisAccountTypeEnum, RoleTypeEnum, SectionName } from '@utils/enums';
import { canCreateConsignment, canViewTemplate, isMyConsignment } from '@utils/question-editable';
import { MutableRefObject } from 'react';

import { AddBuyerModalRef } from './components/AddBuyerModal';
import { AddViewerModalRef } from './components/AddViewerModal';
import { ConfirmModalRef } from './components/ConfirmModal';
import { containsDeprecatedForms, fromPriorSystem } from './ConsignmentHelper';
import { checkIsWithinTransferWindow } from './DeviceTransferHelper';

function GetConsignmentContextMenu(
    consignment: {
        status: string | null;
        createdBy: string;
        number: string;
        type: string | null;
        createdAt?: string;
        pdfUrl?: string;
        movementDate: string;
        origin: any;
        owner: any;
        consignee: any;
        destination: any;
    },
    user: IUser | undefined,
    deleteModalRef: MutableRefObject<ConfirmModalRef | undefined>,
    printModalRef: MutableRefObject<ConfirmModalRef | undefined> | undefined,
    isOnDetailPage: boolean,
    history: any,
    viewerModalRef?: MutableRefObject<AddViewerModalRef | undefined>,
    buyerModalRef?: MutableRefObject<AddBuyerModalRef | undefined>
) {
    const canCreate = canCreateConsignment(user);
    const canView = canViewTemplate(user);
    const isDeprecated = containsDeprecatedForms(consignment);
    const consignmentPDFurl = consignment?.pdfUrl?.startsWith('http') ? consignment?.pdfUrl : Config.BASE_GRAPHQL_SERVER_URL + '/' + consignment?.pdfUrl;
    const globalDispatch = useGlobalDispatch();
    const { hasAnyOfRoles, hasRoleWithConsignment, hasRole } = useRoleValidation();

    const canAccessConsignment = () => {
        if (consignment.status === ConsignmentStatus.DRAFT.toString()) {
            return isMyConsignment(consignment, user!!) || hasRole(RoleTypeEnum.ELEVATEDVIEWER);
        } else if (consignment.status === ConsignmentStatus.SUBMITTED.toString() || consignment.status === ConsignmentStatus.LOCKED.toString()) {
            return !hasRole(RoleTypeEnum.EXPIRED);
        } else {
            return false;
        }
    };

    const canCreateTransfer = () => {
        const isReceiver = user && hasRoleWithConsignment(user?.accountDetails, RoleTypeEnum.RECEIVER, consignment);
        const applicableAccountTypes = [NlisAccountTypeEnum.FEEDLOT, NlisAccountTypeEnum.PRODUCER, NlisAccountTypeEnum.PROCESSOR];
        const isApplicableAccountType = user?.accountDetails.nlisAccountType && applicableAccountTypes.includes(user?.accountDetails.nlisAccountType);

        const movementDate = new Date(consignment.movementDate);
        const isWithinTransferWindow = checkIsWithinTransferWindow(movementDate, consignment.status as ConsignmentStatus);

        return isReceiver && isApplicableAccountType && isWithinTransferWindow;
    };

    const canUpdateConsignment = () => {
        return (
            (consignment.status !== ConsignmentStatus.LOCKED.toString() && isMyConsignment(consignment, user!!)) ||
            (consignment.status === ConsignmentStatus.SUBMITTED && hasRole(RoleTypeEnum.TRANSPORTER))
        );
    };

    const canPreviewPDF = () => {
        return consignment.status === ConsignmentStatus.DRAFT.toString() && (isMyConsignment(consignment, user!!) || hasRole(RoleTypeEnum.ELEVATEDVIEWER));
    };

    if (consignment.type === 'TEMPLATE') {
        const templateNotSupported = fromPriorSystem(consignment);
        return [
            canCreate && !isDeprecated
                ? {
                      id: 125,
                      title: 'Create new consignment from this template',
                      onClick: () => {
                          history.push(`/consignments/copy/${consignment.number}`);
                      },
                  }
                : null,
            templateNotSupported
                ? null
                : canCreate && !isDeprecated
                ? {
                      id: 123,
                      title: 'Edit template',
                      onClick: () => {
                          history.push(`/templates/edit/${consignment.number}/${SectionName.LIVESTOCK_DESCRIPTION}`);
                      },
                  }
                : canView && !isDeprecated
                ? {
                      id: 123,
                      title: 'View template',
                      onClick: () => {
                          history.push(`/templates/edit/${consignment.number}/${SectionName.LIVESTOCK_DESCRIPTION}`);
                      },
                  }
                : null,
            canCreate
                ? {
                      id: 127,
                      title: 'Delete template',
                      className: 'text-error',
                      onClick: () => {
                          const { current } = deleteModalRef;

                          if (current) {
                              current.show();
                          }
                      },
                  }
                : null,
        ].coalesce();
    }

    // Caters for consignments that we don't support ongoing changes to
    const consignmentNotSupported = fromPriorSystem(consignment) || containsDeprecatedForms(consignment);

    const onDeprecatedConsignmentClick = () => {
        if (printModalRef) {
            const { current } = printModalRef;

            if (current) {
                current.show();
            }
        }
    };

    return [
        !isOnDetailPage && canAccessConsignment()
            ? {
                  id: 123,
                  title: canUpdateConsignment() && !consignmentNotSupported ? 'View and Update consignment' : 'View Consignment',
                  subtitle: canUpdateConsignment()
                      ? 'View and make changes to this consignment'
                      : !consignmentNotSupported
                      ? 'View documents and consignment details.'
                      : 'View all the details within the consignment',
                  onClick: () => {
                      !consignmentNotSupported ? history.push(`/consignments/summary/${consignment.number}`) : onDeprecatedConsignmentClick();
                  },
              }
            : null,
        isMyConsignment(consignment, user!!) && !consignmentNotSupported
            ? {
                  id: 126,
                  title: 'Create a new template',
                  subtitle: 'Is this a frequent consignment? Create a template to make creating future consignments quicker',
                  onClick: () => {
                      history.push(`/templates/add/${consignment.number}`);
                  },
              }
            : null,
        isMyConsignment(consignment, user!!) && !consignmentNotSupported
            ? {
                  id: 125,
                  title: 'Duplicate this consignment',
                  subtitle: 'This copies the consignment details into a new one, this is great for one off duplicates',
                  onClick: () => {
                      history.push(`/consignments/copy/${consignment.number}`);
                  },
              }
            : null,
        canAccessConsignment() && !consignmentNotSupported
            ? {
                  id: 130,
                  title: 'View eNVD',
                  subtitle: canPreviewPDF()
                      ? isMyConsignment(consignment, user!!)
                          ? 'Preview the PDF version of this consignment before you submit the consignment.'
                          : 'Preview the PDF version of this draft consignment.'
                      : 'Creates a PDF version of this consignment for you to share.',
                  onClick: () => {
                      window.open(consignmentPDFurl, 'allForms');
                  },
              }
            : null,
        canAccessConsignment() && canCreateTransfer()
            ? {
                  id: 131,
                  title: 'Create Livestock Transfer',
                  subtitle: 'Transfer onto your property now available.',
                  onClick: () => {
                      console.log('Creating transfer');
                  },
              }
            : null,
        isMyConsignment(consignment, user!!) && consignment.status !== ConsignmentStatus.LOCKED.toString() && !consignmentNotSupported
            ? {
                  id: 132,
                  title: 'Add Viewer',
                  subtitle: 'Viewers will be able to see this consignment.',
                  onClick: () => {
                      if (isOnDetailPage) {
                          viewerModalRef?.current?.show();
                      } else {
                          history.push(`/consignments/summary/${consignment.number}`);
                          globalDispatch({
                              type: actionTypes.generic.setOptionStateAction,
                              value: { payload: { isScrollToCommentSection: false, isOpenViewersModal: true, isOpenBuyerModal: false } },
                          });
                      }
                  },
              }
            : null,
        hasRole(RoleTypeEnum.FACILITATOR) && consignment.status === ConsignmentStatus.SUBMITTED.toString() && !consignmentNotSupported
            ? {
                  id: 133,
                  title: 'Add Buyer',
                  subtitle: 'Buyers will be able to see this consignment and their livestock bought.',
                  onClick: () => {
                      if (isOnDetailPage) {
                          buyerModalRef?.current?.show();
                      } else {
                          history.push(`/consignments/summary/${consignment.number}`);
                          globalDispatch({
                              type: actionTypes.generic.setOptionStateAction,
                              value: { payload: { isScrollToCommentSection: false, isOpenViewersModal: false, isOpenBuyerModal: true } },
                          });
                      }
                  },
              }
            : null,
        !hasAnyOfRoles([RoleTypeEnum.BUYER, RoleTypeEnum.ELEVATEDVIEWER, RoleTypeEnum.EXPIRED]) && consignment.status === ConsignmentStatus.SUBMITTED.toString() && !consignmentNotSupported
            ? {
                  id: 134,
                  title: 'Add Comment',
                  subtitle: 'Communicate with other people about this consignment by adding a comment.',
                  onClick: () => {
                      if (isOnDetailPage) {
                          window.scrollTo(0, document.body.scrollHeight);
                      } else {
                          history.push(`/consignments/summary/${consignment.number}`);
                          globalDispatch({
                              type: actionTypes.generic.setOptionStateAction,
                              value: { payload: { isOpenViewersModal: false, isScrollToCommentSection: true, isOpenBuyerModal: false } },
                          });
                      }
                  },
              }
            : null,

        isMyConsignment(consignment, user!!) && consignment.status !== ConsignmentStatus.LOCKED.toString()
            ? {
                  id: 127,
                  title: 'Delete consignment',
                  className: 'text-error',
                  onClick: () => {
                      const { current } = deleteModalRef;

                      if (current) {
                          current.show();
                      }
                  },
              }
            : null,
    ].coalesce();
}

export default GetConsignmentContextMenu;
