import DeviceTransferTag from '@components/DeviceTransferTag';
import Tooltip, { Placement } from '@components/Form/Tooltip';
import { DEVICE_TRANSFER_STATUS_MAP, DeviceTransferStatus } from '@containers/Consignments/DeviceTransferHelper';

type TransFerWithTooltipProps = {
    placement?: Placement;
    status: DeviceTransferStatus;
};

const DeviceTransferTagWithTooltip: React.FC<TransFerWithTooltipProps> = ({ status, placement }) => {
    const { icon, text, className, description } = DEVICE_TRANSFER_STATUS_MAP[status];

    return (
        <Tooltip text={description} placement={placement}>
            <DeviceTransferTag icon={icon} text={text} className={className} />
        </Tooltip>
    );
};

export default DeviceTransferTagWithTooltip;
