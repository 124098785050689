import { DateIcon } from '@assets/icons';
import { useUserState } from '@common/context/userContext';
import Button from '@components/Button';
import Loader from '@components/Loader';
import { ConsignmentDetailQueryResponse } from '@containers/Consignments/__generated__/ConsignmentDetailQuery.graphql';
import { containsDeprecatedForms } from '@containers/Consignments/ConsignmentHelper';
import { cleanConsignmentPIC } from '@containers/Consignments/mutations/createOrSaveConsignment';
import { ConsignmentEditQuery } from '@containers/Consignments/queries/ConsignmentEdit';
import useFileManager from '@effects/useFileManager';
import { useWindowWidth } from '@effects/useWindowWidth';
import { UNKNOWN_PIC } from '@utils/constants';
import { speciesEnumToName } from '@utils/enum-transformers';
import { ConsignmentSpecies } from '@utils/enums';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useQuery } from 'relay-hooks';

import UploadDevice from './UploadDevice';

const UploadDevicesPage: React.FC<{ consignment: ConsignmentDetailQueryResponse['consignment'] }> = ({ consignment }) => {
    const { acceptedFile } = useFileManager();
    const [activeTab, setActiveTab] = useState(0);
    const history = useHistory();
    const width = useWindowWidth();
    const onTabSwitch = (index: number) => {
        setActiveTab(index);
    };
    return (
        <>
            <style jsx>
                {`
                    @import 'vars';
                    @import 'utils';
                    h1 {
                        margin: grid(10) 0;
                        color: $color-secondary;
                        margin: 10px 0px;
                        @media (max-width: $sm-min) {
                            margin: grid(6) 0;
                        }
                    }
                    .grey-text {
                        color: $color-subtitle;
                        cursor: inherit;
                    }
                    .summary-panel {
                        flex: 2 0 65%;

                        .date-info {
                            gap: 10px;
                        }

                        .to-details {
                            border-left: 1px solid $grey-border;
                            position: relative;
                        }
                    }
                    .box {
                        box-sizing: border-box;
                        background-color: $color-white;
                        border: 1px solid $grey-border;
                        border-radius: 4px;
                        @media (prefers-color-scheme: dark) {
                            background-color: darken($color-white, 80%);
                        }
                    }
                    .mainContainer {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        margin-top: 50px;
                    }
                    .child-container {
                        width: 100%;
                    }
                    .tabs-div {
                        display: flex;
                        gap: 10px;
                    }
                    .child-container-tab {
                        background-color: #f2f2f2;
                        border: 2px solid $color-border;
                        border-bottom: 0px;
                        padding: 15px;
                        border-radius: 5px 5px 0px 0px;
                        cursor: pointer;

                        .tabs-label {
                            color: $unselected-tab-text;
                        }

                        &.selected {
                            border: 2px solid $color-secondary;
                            background-color: white;
                            border-bottom: 0px;
                            .tabs-label {
                                color: $color-secondary;
                            }
                        }
                    }
                    .child-container-content {
                        background-color: white;
                        padding: 40px;
                        border-top: 2px solid $color-secondary;
                    }
                    p {
                        margin: auto;
                    }
                    .bold {
                        font-weight: 600;
                    }
                    .horizontal-line {
                        border-top: 1px solid $color-border; /* Creates a black 1px wide line */
                        margin: 15px 0; /* Optional: Add some spacing around the line */
                    }
                `}
            </style>
            <div>
                <h1 data-cy="welcome-heading">{`Add NLISID/eID tags`}</h1>
            </div>
            <div className="summary-panel box m-v-32">
                <div className={`panel-heading ${width > 740 ? 'flex-row flex-between' : 'flex-start-column'} p-v-16 p-h-16`}>
                    <div className="livestock-details-container">
                        <h2>Livestock Details</h2>
                        <div className="flex-center-row m-v-12">
                            <p>Species: {speciesEnumToName(consignment?.species as ConsignmentSpecies)};</p> <p className="p-h-8">Total NLIS/eID tags: {consignment?.numOfAddedDevices};</p>{' '}
                            <p className="bold">Total Headcount: {consignment?.heads}</p>
                        </div>
                    </div>
                    <div className="date-info flex-center-row ">
                        <DateIcon />
                        <label htmlFor="" className="info-label grey-text">
                            Movement Date
                        </label>
                        <p className="bold">{consignment?.movementDate ? new Date(consignment?.movementDate as any).toMLADateString() : ''}</p>
                    </div>
                </div>
            </div>
            <div className="summary-panel box  m-v-32 p-h-16">
                <div className="panel-heading flex-row flex-between p-v-16">
                    <div>
                        <h2>Add NLIS electronic ear tags</h2>
                    </div>
                </div>
                <div className="horizontal-line"></div>
                <div className="mainContainer">
                    <div className="child-container">
                        <div className="tabs-div">
                            <div
                                className={classNames('child-container-tab', {
                                    selected: activeTab === 0,
                                })}
                                onClick={() => onTabSwitch(0)}
                            >
                                <h3 className="tabs-label">Upload your File</h3>
                            </div>
                            <div
                                className={classNames('child-container-tab', {
                                    selected: activeTab === 1,
                                })}
                                onClick={() => onTabSwitch(1)}
                            >
                                <h3 className="tabs-label">Add Manually</h3>
                            </div>
                        </div>
                        <div className="child-container-content p-h-32">
                            <UploadDevice activeTab={activeTab} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-start-row flex-between">
                <Button buttonType="secondary" onClick={() => history.push(`/consignments/summary/${consignment?.number}`)}>
                    Previous page
                </Button>
                <Button buttonType={acceptedFile.length > 0 ? 'primary' : 'disable'} buttonSize="medium">
                    Next step
                </Button>
            </div>
        </>
    );
};

const AddDevices: React.FC = () => {
    const { id } = useParams<any>();
    const [{ user }] = useUserState();
    const history = useHistory();
    const envdAccountId = user?.accountDetails?.id;
    const { data } = useQuery<any>(ConsignmentEditQuery, { id, envdAccountId }, { fetchPolicy: 'network-only', fetchKey: id });

    if (data) {
        const { consignment } = data;
        const containsDeprecated = containsDeprecatedForms(consignment);
        if (consignment && !containsDeprecated) {
            let cloneConsignment = _.clone(consignment);
            if (consignment?.destination?.pic === UNKNOWN_PIC) {
                cloneConsignment = cleanConsignmentPIC(cloneConsignment, 'destination', '');
            }
            return (
                <>
                    <UploadDevicesPage consignment={cloneConsignment} />
                </>
            );
        } else {
            history.replace('/consignments');
            return null;
        }
    } else {
        return <Loader error={''} isLoading pastDelay={false} timedOut={false} retry={() => null} />;
    }
};

export default AddDevices;
