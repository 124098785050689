/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ConsignmentSpecies = "BOBBY_CALVES" | "CATTLE" | "GOAT" | "SHEEP_LAMB" | "%future added value";
export type EFormType = "EUC0" | "EUC1" | "G0517" | "HSC0" | "HSC1" | "HSC2" | "HSG0" | "HSSL0" | "HSSL1" | "LPABC0" | "LPABC1" | "LPAC0" | "LPAC1" | "LPAG0" | "LPAG2" | "LPAG3" | "LPAHRG0" | "LPASL0" | "LPASL1" | "MSAC0" | "MSAC1" | "NFASC0" | "NFASDDC0" | "NFASDDC1" | "NFASEUC0" | "NFASEUC1" | "%future added value";
export type ConsignmentCopySelectTemplateQueryVariables = {
    envdAccountId: string;
};
export type ConsignmentCopySelectTemplateQueryResponse = {
    readonly templates: {
        readonly items: ReadonlyArray<{
            readonly id: string;
            readonly number: string | null;
            readonly name: string | null;
            readonly species: ConsignmentSpecies;
            readonly origin: {
                readonly name: string | null;
            };
            readonly destination: {
                readonly name: string | null;
            };
            readonly forms: ReadonlyArray<{
                readonly type: EFormType | null;
            } | null> | null;
        } | null> | null;
    } | null;
};
export type ConsignmentCopySelectTemplateQuery = {
    readonly response: ConsignmentCopySelectTemplateQueryResponse;
    readonly variables: ConsignmentCopySelectTemplateQueryVariables;
};



/*
query ConsignmentCopySelectTemplateQuery(
  $envdAccountId: String!
) {
  templates(envdAccountId: $envdAccountId) {
    items {
      id
      number
      name
      species
      origin {
        name
      }
      destination {
        name
      }
      forms {
        type
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "envdAccountId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "envdAccountId",
        "variableName": "envdAccountId"
      }
    ],
    "concreteType": "TemplateConnection",
    "kind": "LinkedField",
    "name": "templates",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Template",
        "kind": "LinkedField",
        "name": "items",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "number",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "species",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TemplateOrigin",
            "kind": "LinkedField",
            "name": "origin",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TemplateDestination",
            "kind": "LinkedField",
            "name": "destination",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FormType",
            "kind": "LinkedField",
            "name": "forms",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConsignmentCopySelectTemplateQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConsignmentCopySelectTemplateQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "f5d5e9054cb0cacfbf897f7b493168a0",
    "id": null,
    "metadata": {},
    "name": "ConsignmentCopySelectTemplateQuery",
    "operationKind": "query",
    "text": "query ConsignmentCopySelectTemplateQuery(\n  $envdAccountId: String!\n) {\n  templates(envdAccountId: $envdAccountId) {\n    items {\n      id\n      number\n      name\n      species\n      origin {\n        name\n      }\n      destination {\n        name\n      }\n      forms {\n        type\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c94395ec95db9231853d3eeb49137bd4';
export default node;
