import { DeviceTransferFailedIcon, DeviceTransferPendingIcon, DeviceTransferReadyIcon, DeviceTransferTransferredIcon, DeviceTrasferNotreadyIcon } from '@assets/icons';
import { ConsignmentStatus } from '@utils/enum-transformers';
import { startOfDay } from 'date-fns';

export enum DeviceTransferStatus {
    Ready = 'Ready',
    ReadyWithDevices = 'ReadyWithDevices',
    NotReady = 'NotReady',
    NotReadyWithDevices = 'NotReadyWithDevices',
    Pending = 'Pending',
    Transferred = 'Transferred',
    NotAvailable = 'NotAvailable',
    Failed = 'Failed',
}

export const checkIsWithinTransferWindow = (movementDate: Date, consignmentStatus: ConsignmentStatus): boolean => {
    const todaysDate = startOfDay(new Date());
    return todaysDate >= movementDate && consignmentStatus !== ConsignmentStatus.LOCKED && consignmentStatus !== ConsignmentStatus.DRAFT;
};

export const getDeviceTransferStatus = (hasMovementId: boolean, hasDevices: boolean, movementDate: Date, consignmentStatus: ConsignmentStatus): DeviceTransferStatus => {
    if (hasMovementId) {
        /* Do API lookup for these statuses.

        // Pending
        // Transferred
        // Failed

        return applicable status

        */
    }

    const isWithinTransferWindow = checkIsWithinTransferWindow(movementDate, consignmentStatus);

    if (isWithinTransferWindow) {
        return hasDevices ? DeviceTransferStatus.ReadyWithDevices : DeviceTransferStatus.Ready;
    }

    if (consignmentStatus === ConsignmentStatus.LOCKED) {
        return DeviceTransferStatus.NotAvailable;
    }

    return hasDevices ? DeviceTransferStatus.NotReadyWithDevices : DeviceTransferStatus.NotReady;
};

export const DEVICE_TRANSFER_STATUS_MAP: Record<DeviceTransferStatus, { icon: React.ReactNode; text: string; className: string; description: string }> = {
    [DeviceTransferStatus.Ready]: {
        icon: null,
        text: 'Ready',
        className: 'ready',
        description: 'The transfer is available starting today for the next 7 days. Please proceed during this time.',
    },
    [DeviceTransferStatus.ReadyWithDevices]: {
        icon: <DeviceTransferReadyIcon className="icon-xxs" alt="Ready with tags" />,
        text: 'Ready',
        className: 'ready',
        description: 'Tags have been added by the sender and can be reviewed now. The transfer is available starting today for the next 7 days. Please proceed during this time.',
    },
    [DeviceTransferStatus.NotReady]: {
        icon: null,
        text: 'Not ready',
        className: 'not-ready',
        description: 'NLIS devices have not been added yet, and the movement cannot be submitted until the movement date.',
    },
    [DeviceTransferStatus.NotReadyWithDevices]: {
        icon: <DeviceTrasferNotreadyIcon className="icon-xxs" alt="Not ready with tags" />,
        text: 'Not ready',
        className: 'not-ready',
        description: 'NLIS devices have been added to the consignments by the sender and can be previewed. NLIS movement cannot be submitted until the movement date.',
    },
    [DeviceTransferStatus.NotAvailable]: {
        icon: null,
        text: 'Not available',
        className: 'not-available',
        description: 'Transfer is unavailable as it has exceeded the movement date plus 7 days.',
    },
    [DeviceTransferStatus.Transferred]: {
        icon: <DeviceTransferTransferredIcon className="icon-xxs" alt="transferred" />,
        text: 'Transferred',
        className: 'transferred',
        description: 'Transfer successful. Please review the outcome.',
    },
    [DeviceTransferStatus.Pending]: {
        icon: <DeviceTransferPendingIcon className="icon-xxs" alt="pending" />,
        text: 'Pending',
        className: 'pending',
        description: 'Transfer is in progress. Please check back later for the outcome.',
    },
    [DeviceTransferStatus.Failed]: {
        icon: <DeviceTransferFailedIcon className="icon-xxs" alt="failed" />,
        text: 'Failed',
        className: 'failed',
        description: 'Transfer unsuccessful. Please review the outcome and try again.',
    },
};
