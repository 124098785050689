
import * as React from 'react';

const DeviceTransferReadyIcon = ({color = "#1C4E9C", ...props }) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
    <path
        d="M19.41 9.58L10.41 0.58C10.04 0.21 9.53 0 9 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V9C0 9.53 0.21 10.04 0.59 10.41L1 10.81C1.9 10.27 2.94 10 4 10C5.5913 10 7.11742 10.6321 8.24264 11.7574C9.36786 12.8826 10 14.4087 10 16C10 17.06 9.72 18.09 9.18 19L9.58 19.4C9.95 19.78 10.47 20 11 20C11.53 20 12.04 19.79 12.41 19.41L19.41 12.41C19.79 12.04 20 11.53 20 11C20 10.47 19.79 9.96 19.41 9.58ZM3.5 5C3.10218 5 2.72064 4.84196 2.43934 4.56066C2.15804 4.27936 2 3.89782 2 3.5C2 3.10218 2.15804 2.72064 2.43934 2.43934C2.72064 2.15804 3.10218 2 3.5 2C3.89782 2 4.27936 2.15804 4.56066 2.43934C4.84196 2.72064 5 3.10218 5 3.5C5 3.89782 4.84196 4.27936 4.56066 4.56066C4.27936 4.84196 3.89782 5 3.5 5ZM8 17H5V20H3V17H0V15H3V12H5V15H8V17Z"
        fill={color}
    />
    </svg>
  );
};

export default DeviceTransferReadyIcon;

