
import React from "react";

const DeviceTransferFailedIcon = (props) => {
  return (
    <svg
    {...props}
      width={24}
      height={24}
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2759 6.72065L7.27594 0.720651C7.02927 0.473984 6.68927 0.333984 6.33594 0.333984H1.66927C1.31565 0.333984 0.97651 0.47446 0.726462 0.724509C0.476413 0.974557 0.335938 1.3137 0.335938 1.66732V6.33398C0.335938 6.68732 0.475937 7.02732 0.729271 7.27398L1.0026 7.54065C1.6026 7.18065 2.29594 7.00065 3.0026 7.00065C4.06347 7.00065 5.08089 7.42208 5.83103 8.17222C6.58118 8.92237 7.0026 9.93979 7.0026 11.0007C7.0026 11.7073 6.81594 12.394 6.45594 13.0007L6.7226 13.2673C6.96927 13.5207 7.31594 13.6673 7.66927 13.6673C8.0226 13.6673 8.3626 13.5273 8.60927 13.274L13.2759 8.60732C13.5293 8.36065 13.6693 8.02065 13.6693 7.66732C13.6693 7.31398 13.5293 6.97398 13.2759 6.72065ZM2.66927 3.66732C2.40405 3.66732 2.1497 3.56196 1.96216 3.37442C1.77463 3.18689 1.66927 2.93253 1.66927 2.66732C1.66927 2.4021 1.77463 2.14775 1.96216 1.96021C2.1497 1.77267 2.40405 1.66732 2.66927 1.66732C2.93449 1.66732 3.18884 1.77267 3.37638 1.96021C3.56391 2.14775 3.66927 2.4021 3.66927 2.66732C3.66927 2.93253 3.56391 3.18689 3.37638 3.37442C3.18884 3.56196 2.93449 3.66732 2.66927 3.66732ZM4.41594 13.3607L3.0026 11.9407L1.58927 13.3607L0.642604 12.414L2.0626 11.0007L0.642604 9.58732L1.5826 8.64732L3.0026 10.0607L4.41594 8.64732L5.35594 9.58732L3.9426 11.0007L5.35594 12.414L4.41594 13.3607Z"
        fill="#C64E3E"
      />
    </svg>
  );
};

export default DeviceTransferFailedIcon;
