import { checkIsWithinTransferWindow } from '@containers/Consignments/DeviceTransferHelper';
import { ConsignmentStatus } from '@utils/enum-transformers';
import { NlisAccountTypeEnum } from '@utils/enums';
import { isAfter, startOfDay } from 'date-fns';

const useMovement = () => {
    /**
     * Check whether can add or edit devices
     * @param isProducer
     * @param movementDate
     */
    const canAddOrEditDevices = (isProducer: boolean, movementDate: Date): boolean => {
        const todaysDate = startOfDay(new Date());
        const isBeforeOrSameAsMovementDate = !isAfter(todaysDate, movementDate);

        return isProducer && isBeforeOrSameAsMovementDate;
    };

    /**
     * Check whether user can perform a movement (transfer)
     * @param isReceiver
     * @param movementDate
     * @param consignmentStatus
     * @param userNlisAccountType
     */
    const canPerformMovement = (isReceiver: boolean, movementDate: Date, consignmentStatus: ConsignmentStatus, userNlisAccountType: any): boolean => {
        const applicableAccountTypes = [NlisAccountTypeEnum.FEEDLOT, NlisAccountTypeEnum.PRODUCER, NlisAccountTypeEnum.PROCESSOR];
        const isApplicableAccountType = applicableAccountTypes.includes(userNlisAccountType) || false;

        const isWithinTransferWindow = checkIsWithinTransferWindow(movementDate, consignmentStatus);

        return isReceiver && isApplicableAccountType && isWithinTransferWindow;
    };

    return { canAddOrEditDevices, canPerformMovement };
};

export default useMovement;
