import React, { ReactNode } from 'react';

type BannerProps = {
    text: string;
    children: ReactNode;
};

const Banner = ({ children, text }: BannerProps) => {
    return (
        <>
            <div className="flex-column">
                <div className="banner p-v-4 p-h-16">{text}</div>
                {children}
            </div>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .banner {
                    @include text-small();
                    width: fit-content;
                    background-color: $color-new-functionality;
                    color: $color-white;
                    border-radius: grid(1) grid(1) 0 0;
                }
            `}</style>
        </>
    );
};

export default Banner;
