import classnames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
    icon: ReactNode;
    text: string;
    className: string;
}

const DeviceTransferTag: React.FunctionComponent<Props> = ({ icon, text, className }) => {
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .Tag {
                    @include text-topic;
                    @include text-title;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: grid(1.5);
                    padding: grid(1.5) grid(4);
                    border-radius: 12px;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                    min-width: grid(24);
                    text-wrap: nowrap;
                }

                .ready {
                    background: rgba($color-transfer-ready, 0.1);
                    color: $color-transfer-ready;
                }

                .not-ready {
                    background: rgba($color-transfer-not-ready, 0.1);
                    color: $color-transfer-not-ready;
                }

                .transferred {
                    background: rgba($color-transfer-transferred, 0.1);
                    color: $color-transfer-transferred;
                }

                .pending {
                    background: rgba($color-transfer-pending, 0.1);
                    color: $color-transfer-pending;
                }

                .failed,
                .not-available {
                    background: rgba($color-transfer-failed, 0.1);
                    color: $color-transfer-failed;
                }
            `}</style>

            <div className={classnames('Tag', className)}>
                {icon}
                {text}
            </div>
        </>
    );
};
export default DeviceTransferTag;
