import MultilineTextArea from '@components/Form/MultilineTexArea';
import { useWindowWidth } from '@effects/useWindowWidth';
import React, { ReactNode, useState } from 'react';

interface ManualDeviceInputProps {
    children?: ReactNode;
}
const ManualDeviceInput: React.FunctionComponent<ManualDeviceInputProps> = ({ children }) => {
    const width = useWindowWidth();
    const [tagValue, setTagValue] = useState<string>('');
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTagValue(event.target.value);
    };
    return (
        <>
            <style jsx>
                {`
                    .upload-container {
                        width: 70%;
                        min-width: 300px;
                    }
                `}
            </style>
            <div className={`${width > 740 ? 'flex-start-row' : 'flex-center-column'} m-v-32`} style={{ width: '100%', gap: 15 }}>
                {children}
                <MultilineTextArea rows={10} labelText={'NLISID or RFID'} value={tagValue} onChange={handleChange} />
            </div>
        </>
    );
};

export default ManualDeviceInput;
