import React from 'react';

export const ProgressBar: React.FC<{ completed: number }> = ({ completed }) => {
    return (
        <>
            <div className="bar-container">
                <div className="filler"></div>
            </div>
            <style jsx>{`
                @import 'vars';

                .bar-container {
                    height: 10px;
                    width: 100%;
                    background-color: $light-grey;
                    border-radius: 10px;
                }

                .filler {
                    height: 100%;
                    width: ${completed}%;
                    background-color: $isc-blue;
                    border-radius: inherit;
                    text-align: right;
                }
            `}</style>
        </>
    );
};
