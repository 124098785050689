import * as React from 'react';
const SvgCsvFile = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
        <path fill="#185C37" d="M19.581 15.35 8.512 13.4v14.409A1.19 1.19 0 0 0 9.705 29h19.1A1.19 1.19 0 0 0 30 27.809V22.5z" />
        <path fill="#21A366" d="M19.581 3H9.705a1.19 1.19 0 0 0-1.193 1.191V9.5L19.581 16l5.861 1.95L30 16V9.5z" />
        <path fill="#107C41" d="M8.512 9.5h11.069V16H8.512z" />
        <path fill="#000" d="M16.434 8.2H8.512v16.25h7.922a1.2 1.2 0 0 0 1.194-1.191V9.391A1.2 1.2 0 0 0 16.434 8.2" opacity={0.1} />
        <path fill="#000" d="M15.783 8.85H8.512V25.1h7.271a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191" opacity={0.2} />
        <path fill="#000" d="M15.783 8.85H8.512V23.8h7.271a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191" opacity={0.2} />
        <path fill="#000" d="M15.132 8.85h-6.62V23.8h6.62a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191" opacity={0.2} />
        <path fill="url(#csvFile_svg__a)" d="M3.194 8.807h11.938a1.193 1.193 0 0 1 1.194 1.191v11.918a1.193 1.193 0 0 1-1.194 1.191H3.194A1.19 1.19 0 0 1 2 21.917V9.997a1.19 1.19 0 0 1 1.194-1.19" />
        <path fill="#33C481" d="M28.806 3h-9.225v6.5h10.42V4.191A1.19 1.19 0 0 0 28.805 3" />
        <path fill="#107C41" d="M19.581 16h10.42v6.5H19.58z" />
        <path
            fill="#fff"
            d="m15.422 13.585-1.654 4.801h-1.225l-1.634-4.801h1.165l1.001 3.341q.08.27.097.48h.02q.024-.225.104-.493l.995-3.328zM7.36 18.202v-1.071q.29.244.632.368.342.12.69.12.204 0 .355-.036a.8.8 0 0 0 .254-.1.394.394 0 0 0 .204-.348.4.4 0 0 0-.08-.252.9.9 0 0 0-.22-.204 2 2 0 0 0-.329-.181 12 12 0 0 0-.412-.178q-.562-.234-.84-.572a1.25 1.25 0 0 1-.275-.817q0-.375.15-.643.151-.271.41-.445.26-.174.602-.255a3 3 0 0 1 .723-.083q.375 0 .663.046.291.044.536.138v1a1.887 1.887 0 0 0-.556-.251 2 2 0 0 0-.582-.083q-.185 0-.335.037a.8.8 0 0 0-.255.097.5.5 0 0 0-.16.154.388.388 0 0 0 .007.415.8.8 0 0 0 .18.18q.117.085.285.168.167.081.378.168.288.12.516.258.23.133.395.304.164.171.25.392.088.218.088.509 0 .402-.154.676-.15.271-.412.442a1.8 1.8 0 0 1-.61.241q-.345.075-.73.074a4 4 0 0 1-.753-.067 2.3 2.3 0 0 1-.616-.2M6.743 18.215q-.525.255-1.373.255-1.104 0-1.737-.65T3 16.09q0-1.152.71-1.869.712-.716 1.848-.716.704 0 1.185.177v1.041a2.1 2.1 0 0 0-1.098-.288q-.676 0-1.091.426-.416.425-.416 1.151 0 .697.392 1.112.392.412 1.055.412a2.25 2.25 0 0 0 1.158-.308z"
        />
        <defs>
            <linearGradient id="csvFile_svg__a" x1={4.494} x2={13.832} y1={7.871} y2={24.043} gradientUnits="userSpaceOnUse">
                <stop stopColor="#18884F" />
                <stop offset={0.5} stopColor="#117E43" />
                <stop offset={1} stopColor="#0B6631" />
            </linearGradient>
        </defs>
    </svg>
);
export default SvgCsvFile;

