// DevicesFileContext.tsx
import React, { createContext, useContext, useState } from 'react';
import { FileWithPath } from 'react-dropzone'; // Import FileWithPath type

// Define the types for the context
type FileContextType = {
    acceptedFile: FileWithPath[]; // Explicitly type the acceptedFile array
    setAcceptedFile: (files: FileWithPath[]) => void;
};

const FileContext = createContext<FileContextType | undefined>(undefined);

// Hook to access the context
export const useFileContext = () => {
    const context = useContext(FileContext);
    if (!context) {
        throw new Error('useFileContext must be used within a FileProvider');
    }
    return context;
};

export const FileProvider = ({ children }: { children: React.ReactNode }) => {
    const [acceptedFile, setAcceptedFile] = useState<FileWithPath[]>([]);

    return <FileContext.Provider value={{ acceptedFile, setAcceptedFile }}>{children}</FileContext.Provider>;
};
