import UserEdit from '@components/UserSelect/UserEdit';
import UserView from '@components/UserSelect/UserView';
import { IPerson, IUserSelectField } from '@typings';
import React, { useEffect, useState } from 'react';

export interface UserSelectProps {
    labelText?: string;
    person: IPerson;

    onChange: (p: IPerson) => void;
    onCancel?: () => void;

    indent?: boolean;
    showEdit?: {
        title: string;
        active: boolean;
        onClicked?: () => void;

        expandOnDefault?: boolean;
    };
    showChange?: {
        title: string;
        active: boolean;
        onClicked?: () => void;
    };

    additionalFields?: IUserSelectField[];
    isReadOnly?: boolean;
    displayNonPICField?: boolean;
    checkEdit?: (value: boolean | undefined) => void;
}

const UserSelect: React.FunctionComponent<UserSelectProps> = ({
    labelText,
    person,
    onChange,
    onCancel,
    showEdit,
    showChange,
    indent,
    additionalFields,
    isReadOnly,
    displayNonPICField = true,
    checkEdit,
}) => {
    const [edit, setEdit] = useState(showEdit && showEdit.expandOnDefault);

    const saveEdit = (newPerson: IPerson) => {
        onChange({
            ...newPerson,

            // Prefill correct inputs when nothing assigned
            pic: newPerson.pic ? newPerson.pic : '',
            locationState: newPerson.locationState ? newPerson.locationState : undefined,
            locationAddress: newPerson.locationAddress ? newPerson.locationAddress : undefined,
            locationPostcode: newPerson.locationPostcode ? newPerson.locationPostcode : undefined,
            locationTown: newPerson.locationTown ? newPerson.locationTown : undefined,
        });
        setEdit(false);
    };
    useEffect(() => {
        if (checkEdit) {
            checkEdit(edit);
        }
    }, [edit, checkEdit]);

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .UserSelect {
                    padding: 0 0 grid(8) 0;
                }
                :global(.conditional--content .UserSelect) {
                    padding-bottom: 0;
                }
            `}</style>

            <div className="UserSelect">
                <h3 className="m-b-16">{labelText}</h3>

                {edit ? (
                    <UserEdit
                        person={person}
                        onChange={saveEdit}
                        onCancel={() => {
                            if (onCancel) {
                                onCancel();
                            }
                            setEdit(false);
                        }}
                        additionalFields={additionalFields}
                        displayNonPICField={displayNonPICField}
                    />
                ) : (
                    <UserView
                        showEdit={
                            !isReadOnly
                                ? showEdit
                                    ? {
                                          ...showEdit,
                                          onClicked: () => {
                                              setEdit(!edit);
                                              if (showEdit.onClicked) {
                                                  showEdit.onClicked();
                                              }
                                          },
                                      }
                                    : { title: 'Edit details', active: true, onClicked: () => setEdit(!edit) }
                                : undefined
                        }
                        person={person}
                        showChange={isReadOnly ? undefined : showChange}
                        indent={indent}
                        displayNonPICField={displayNonPICField}
                    />
                )}
            </div>
        </>
    );
};
export default UserSelect;
