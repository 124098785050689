export const UNKNOWN_PIC = 'AAAAAAAA';
export const UNKNOWN_PIC_DESTINATION = 'EEEEEEEE';

export const MOBILE_OR_TABLET_WIDTH = 768;
export const DESKTOP_WIDTH = 1024;

export const FILE_REGEX = /file:[a-zA-Z_]+\.(jpg|jpeg|png);/g;
export const BLANK_IMAGE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAQAAABeK7cBAAAAC0lEQVR42mNkAAIAAAoAAv/lxKUAAAAASUVORK5CYII=';

export const ACCEPTED_FILE_EXTENSIONS_IMAGES = '.jpg, .jpeg, .png';
export const ACCEPTED_FILE_EXTENSIONS = '.jpg, .jpeg, .png, .pdf, .heic, .heif';
export const ACCEPTED_IMAGE_EXTENSION_LABEL = 'JPG, PNG';
export const ONE_MB_BYTES = 1 * 1024 * 1024;
export const ACCEPTED_FILE_EXTENSION_LABEL = 'JPG, PDF, PNG or HEIC';
export const ACCEPTED_MIMETYPES_IMAGES = ['image/jpg', 'image/jpeg', 'image/png', 'image/heic', 'image/heif'];
export const ACCEPTED_MIMETYPES_FILES = ['application/pdf'];
export const ACCEPTED_MIMETYPES = [...ACCEPTED_MIMETYPES_FILES, ...ACCEPTED_MIMETYPES_IMAGES];
export const ACCEPTED_FILESIZE = 5;

export const REGISTRATION_PATH = '/register';
export const WELCOME_PATH = '/';
export const ALLOWED_NEW_USER_PATHS = [REGISTRATION_PATH, WELCOME_PATH];
export const PHONE_REGEX = new RegExp('^(?:\\+?61|0)[2-478](?:[ -]?[0-9]){8}$');
export const EDITABLE_AND_DELETABLE_IDS_V4 = ['1', '157'];
export const HELPDESK_NUMBER = '1800 683 111';
export const FETCH_USER_DETAILS_ERROR_MSG = `Oops! It seems that something isn't quite right, but rest assured, we're working on fixing it. Please try again later or call ISC Helpdesk at ${HELPDESK_NUMBER}.`;
export const TRANSPORTER_EMAIL_QUESTION_ID = '182';
export const TRANSPORTER_QUESTION_TEXT = 'Transporter';
export const INVALID_PHONE_NUMBER = 'Please enter a valid phone number (e.g. 0299999999 or +61400000000)';
export const INVALID_POSTCODE = 'Please enter valid 4 digit Postcode';
export const CONSIGNMENT_SUBMITTED_TITLE = 'This consignment has been submitted';
export const CONSIGNMENT_SUBMITTED_MESAGE =
    'The creator of this consignment can still change the movement date, livestock description and transporter details until 7 days after movement date and time.';
export const CONSIGNMENT_COMMENT_DIALOG_MESSAGE = 'Once a comment is added, it cannot be edited or deleted.';
export const CONSIGNMENT_COMMENT_FACILITATOR_ONLY_MESSAGE = 'Buyers cannot see any comments you make here.';
export const COMMENT_MAXLENGTH = 256;
export const NOTEDITABLE_WHEN_DECLARETICKED_IDS = ['158', '159'];
export const TRANSPORTER_DECLARED_BOX_QUESTION_ID = '164';
export const REQUIRED_WHEN_VALIDATOR_TYPE = 'requiredWhen';
export const UNIQUE_WHEN_VALIDATOR_TYPE = 'unique';
export const COMBO_PARAMETERS: any = { '158': 'email', '159': 'rego' };
export const TRANSPORTER_DELETION_ERROR_CODE = '1015';
export const CSV_FILE_TYPE = 'text/csv';
export const TXT_FILE_TYPE = 'text/plain';
