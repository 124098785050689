/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConsignmentSpecies = "BOBBY_CALVES" | "CATTLE" | "GOAT" | "SHEEP_LAMB" | "%future added value";
export type ConsignmentStatus = "DELETED" | "DRAFT" | "LOCKED" | "SUBMITTED" | "%future added value";
export type EFormType = "EUC0" | "EUC1" | "G0517" | "HSC0" | "HSC1" | "HSC2" | "HSG0" | "HSSL0" | "HSSL1" | "LPABC0" | "LPABC1" | "LPAC0" | "LPAC1" | "LPAG0" | "LPAG2" | "LPAG3" | "LPAHRG0" | "LPASL0" | "LPASL1" | "MSAC0" | "MSAC1" | "NFASC0" | "NFASDDC0" | "NFASDDC1" | "NFASEUC0" | "NFASEUC1" | "%future added value";
export type ConsignmentAddSelectTemplateRow_template = {
    readonly id: string;
    readonly number: string | null;
    readonly status: ConsignmentStatus;
    readonly name: string | null;
    readonly createdBy: string;
    readonly forms: ReadonlyArray<{
        readonly type: EFormType | null;
    } | null> | null;
    readonly origin: {
        readonly name: string | null;
    };
    readonly destination: {
        readonly name: string | null;
    };
    readonly species: ConsignmentSpecies;
    readonly " $refType": "ConsignmentAddSelectTemplateRow_template";
};
export type ConsignmentAddSelectTemplateRow_template$data = ConsignmentAddSelectTemplateRow_template;
export type ConsignmentAddSelectTemplateRow_template$key = {
    readonly " $data"?: ConsignmentAddSelectTemplateRow_template$data;
    readonly " $fragmentRefs": FragmentRefs<"ConsignmentAddSelectTemplateRow_template">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConsignmentAddSelectTemplateRow_template",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FormType",
      "kind": "LinkedField",
      "name": "forms",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TemplateOrigin",
      "kind": "LinkedField",
      "name": "origin",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TemplateDestination",
      "kind": "LinkedField",
      "name": "destination",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "species",
      "storageKey": null
    }
  ],
  "type": "Template",
  "abstractKey": null
};
})();
(node as any).hash = '23a22015c4cc71ef9509e2c7e8a47ca5';
export default node;
