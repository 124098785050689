import classNames from 'classnames';
import { ReactNode, useState } from 'react';

export type Placement = 'top' | 'bottom' | 'left' | 'right';

type TooltipProps = {
    title?: string;
    text: string;
    children: ReactNode;
    placement?: Placement;
};

const Tooltip = ({ title, text, children, placement = 'top' }: TooltipProps) => {
    const [isVisible, setIsVisible] = useState(false);

    // Dynamically generate the class names based on the arrow position
    const tooltipClasses = classNames('tooltip', {
        'tooltip--top': placement === 'top',
        'tooltip--bottom': placement === 'bottom',
        'tooltip--left': placement === 'left',
        'tooltip--right': placement === 'right',
    });

    return (
        <div className="tooltip-container" onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
            {children}
            {isVisible && (
                <div className={tooltipClasses}>
                    <div className="flex-start-column">
                        {title && <h3>{title}</h3>}
                        <p className="m-v-12">{text}</p>
                    </div>
                </div>
            )}
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .tooltip-container {
                    position: relative;
                    display: inline-block;
                }

                .tooltip {
                    position: absolute;
                    background-color: $color-tooltip;
                    color: $color-white;
                    padding: grid(1) grid(4);
                    border-radius: grid(1);
                    white-space: normal;
                    min-width: grid(80);
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                    p {
                        @include text-tooltip;
                        color: $color-white;
                    }
                }

                .tooltip::after {
                    content: '';
                    position: absolute;
                    border-width: grid(1.5); /* Size of the arrow */
                    border-style: solid;
                }

                /* Tooltip positioning for different arrow positions */
                .tooltip--top {
                    bottom: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    margin-bottom: grid(2); /* Space for the arrow */
                }

                .tooltip--bottom {
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    margin-top: grid(2); /* Space for the arrow */
                }

                .tooltip--left {
                    top: 50%;
                    right: 100%;
                    transform: translateY(-50%);
                    margin-right: grid(2); /* Space for the arrow */
                }

                .tooltip--right {
                    top: 50%;
                    left: 100%;
                    transform: translateY(-50%);
                    margin-left: grid(2); /* Space for the arrow */
                }

                /* Arrow-specific styles for different positions */
                .tooltip--top::after {
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    border-color: $color-tooltip transparent transparent transparent;
                }

                .tooltip--bottom::after {
                    bottom: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    border-color: transparent transparent $color-tooltip transparent;
                }

                .tooltip--left::after {
                    top: 50%;
                    left: 100%;
                    transform: translateY(-50%);
                    border-color: transparent transparent transparent $color-tooltip;
                }

                .tooltip--right::after {
                    top: 50%;
                    right: 100%;
                    transform: translateY(-50%);
                    border-color: transparent $color-tooltip transparent transparent;
                }
            `}</style>
        </div>
    );
};

export default Tooltip;
