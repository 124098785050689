import EmptyList from '@common/components/Listing/EmptyList';
import Loader from '@common/components/Loader';
import { useUserState } from '@common/context/userContext';
import Config from '@config';
import useRoleValidation from '@effects/useRoleValidation';
import { IUser } from '@typings';
import { RoleTypeEnum } from '@utils/enums';
import MLALogger from '@utils/logger';
import { canCreateConsignment, hasRoles } from '@utils/question-editable';
import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { ConnectionConfig } from 'react-relay';
import { useHistory } from 'react-router';
import { graphql, usePagination } from 'relay-hooks';

import { ConsignmentListFragment_query } from './__generated__/ConsignmentListFragment_query.graphql';
import ConsignmentRow from './ConsignmentRow';

interface Props {
    query: ConsignmentListFragment_query;

    isSearchOrFilter: boolean;
}

export const Header: React.FC<{ user: IUser | undefined; showMovementDate: boolean; headerClassName?: string }> = ({ user, showMovementDate, headerClassName }) => {
    const isPicBased = user?.accountDetails?.isPicBased;
    const { hasRole } = useRoleValidation();
    const isBuyer = hasRole(RoleTypeEnum.BUYER);
    return (
        <div className={!!headerClassName ? headerClassName : 'tbl-row row-header'}>
            <div className="tbl-cell-30 column-heading">Consignment</div>
            {isPicBased && <div className="tbl-cell-15 column-heading" />}
            {isBuyer && <div className="tbl-cell-15 column-heading">Destination PIC</div>}
            {showMovementDate && <div className="tbl-cell-15 column-heading">Movement Date</div>}
            <div className="tbl-cell-15 column-heading">{isBuyer ? 'Livestock Bought' : 'Livestock'}</div>
            <div className="tbl-cell-15 column-heading">Forms</div>
            <div className="tbl-cell-15 column-heading">Status</div>
            <div className="tbl-cell-15 column-heading">Tag Transfer</div>
            <div className="tbl-cell-15 column-heading" />
        </div>
    );
};

const Empty = ({ isSearchOrFilter }: { isSearchOrFilter: boolean }) => {
    const [{ user }] = useUserState();
    const history = useHistory();

    const hasRole = hasRoles(user);
    const canCreate = canCreateConsignment(user);

    if (hasRole) {
        if (canCreate) {
            return isSearchOrFilter ? (
                <EmptyList
                    emptyText={
                        <>
                            <h1>No results found</h1>
                            <br />
                            <p>We couldn't find the consignments you were looking for.</p>
                            <p>Update your search or filter parameters and try again.</p>
                        </>
                    }
                />
            ) : (
                <EmptyList emptyText="You currently have no consignments, would you like to create one?" actionText="Create consignment" action={() => history.push('/consignments/add')} />
            );
        }

        // Error msg change considering NLIS user will use eNVD portal
        return <EmptyList emptyText="There are no consignments associated with this profile" />;
    } else {
        // Display empty list without any msg when the user details query is failed and assign a default empty user
        return <EmptyList emptyText={`\n`} />;
    }
};

export const ConsignmentListFragmentQuery = graphql`
    query ConsignmentListFragmentPagedQuery(
        $envdAccountId: String!
        $count: Int!
        $cursor: String
        $searchText: String
        $species: ConsignmentSpecies
        $status: ConsignmentStatus
        $fromDate: DateTime
        $toDate: DateTime
        $sortField: ConsignmentSortFieldEnum
    ) {
        ...ConsignmentListFragment_query
    }
`;

export const ConsignmentListPaginationSpec = graphql`
    fragment ConsignmentListFragment_query on Query @refetchable(queryName: "ConsignmentListRefetchQuery") {
        consignments(
            envdAccountId: $envdAccountId
            first: $count
            after: $cursor
            searchText: $searchText
            species: $species
            status: $status
            fromDate: $fromDate
            toDate: $toDate
            sortField: $sortField
        ) @connection(key: "ConsignmentListFragment_consignments") {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                    id
                    ...ConsignmentRow_consignment
                    updatedAt
                    forms {
                        type
                    }
                }
            }
        }
    }
`;

export const ConsignmentListConnectionConfig: ConnectionConfig = {
    direction: 'forward',
    query: ConsignmentListFragmentQuery as any,
    // Don't use props because it complains about null errors in pageInfo and edges...
    getConnectionFromProps(props: any) {
        return props?.consignments;
    },
    getVariables(props: any, paginationInfo: any, fragmentVariables: any) {
        return {
            count: paginationInfo.count,
            cursor: props.consignments.pageInfo.endCursor,
            searchText: fragmentVariables.searchText,
            species: fragmentVariables.species,
            status: fragmentVariables.status,
            fromDate: fragmentVariables.fromDate,
            toDate: fragmentVariables.toDate,
            sortField: fragmentVariables.sortOrder,
        };
    },
};

const ConsignmentListFragment: React.FunctionComponent<Props> = ({ query, isSearchOrFilter }) => {
    const { data, isLoading, hasNext, loadNext } = usePagination(ConsignmentListPaginationSpec, query as any);
    const consignmentsList = data?.consignments?.edges?.map((c: any) => c && c.node).coalesce();
    const [{ user }] = useUserState();
    const _loadMore = useCallback(() => {
        if (!hasNext || isLoading) {
            MLALogger.Log(['ConsignmentList'], 'Skipped loading');
            return;
        }
        loadNext(Config.PAGINATION_PER_PAGE_LIMIT);
    }, [hasNext, isLoading, loadNext]);

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';
                .tbl {
                    @media (max-width: $md-max) {
                        border: none;
                        background: transparent;
                    }
                }
            `}</style>
            <div className="tbl tbl-collapse">
                <Header user={user} showMovementDate={true} />
                <InfiniteScroll
                    className="tbl-body"
                    data-cy="consignment-records"
                    pageStart={0}
                    initialLoad={false}
                    loadMore={() => {
                        if (!hasNext || isLoading) {
                            console.log('[ConsignmentList] Relay has no more or is currently loading', { hasMore: hasNext, isLoading });
                            return;
                        }

                        console.log('[ConsignmentList] Relay is attempting to load more', { hasMore: hasNext, isLoading });

                        _loadMore();
                    }}
                    hasMore={hasNext}
                    loader={
                        <div className="tbl-row" key={0}>
                            <Loader isLoading={true} error={''} retry={() => null} timedOut={false} pastDelay={false} />
                        </div>
                    }
                >
                    {consignmentsList?.length === 0 ? (
                        <Empty isSearchOrFilter={isSearchOrFilter} />
                    ) : (
                        consignmentsList?.map((c: any) => {
                            return <ConsignmentRow key={c!.id} consignment={c!} showMovementDate={true} />;
                        })
                    )}
                </InfiniteScroll>
            </div>
        </>
    );
};

export const ConsignmentsLoader = () => {
    return (
        <div className="tbl tbl-collapse">
            <Header user={undefined} showMovementDate={true} />
            <Loader isLoading={true} error={false} retry={() => null} timedOut={false} pastDelay={false} />
        </div>
    );
};

// Need subscriptions? https://github.com/howtographql/react-relay/blob/master/src/subscriptions/NewVoteSubscription.js

export default ConsignmentListFragment;
