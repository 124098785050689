import Spinner from '@components/Loader/Spinner';
import classnames from 'classnames';
import React from 'react';

export type ButtonType = 'primary' | 'secondary' | 'tertiary' | 'delete' | 'link' | 'redirect' | 'quaternary' | 'disable' | 'reset';
export type ButtonSize = 'small' | 'xsmall' | 'normal' | 'medium' | 'full-width';

export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    children: React.ReactNode;
    buttonType: ButtonType;
    buttonSize?: ButtonSize;
    disabled?: boolean;
    className?: any;
    action?: () => void;
    url?: string;
    download?: string;
    fullWidth?: boolean;
    warn?: boolean;
    active?: boolean;
    loading?: boolean;
    openNewTab?: boolean;
}

const Button: React.FC<ButtonProps> = ({ buttonType, children, buttonSize, className, disabled, action, url, download, fullWidth = false, warn, active, loading, openNewTab, id, ...rest }) => {
    const buttonClasses = classnames(
        'Button',
        {
            'Button--Primary': buttonType === 'primary',
            'Button--Secondary': buttonType === 'secondary',
            'Button--Tertiary': buttonType === 'tertiary',
            'Button--Delete': buttonType === 'delete',
            'Button--Small': buttonSize === 'small',
            'Button--X-Small': buttonSize === 'xsmall',
            'Button--Medium': buttonSize === 'medium',
            'Button--Link': buttonType === 'link',
            'Button--Quaternary': buttonType === 'quaternary',
            'Button--Redirect': buttonType === 'redirect',
            'Button--Block': fullWidth,
            'Button--Warn': warn,
            'Button--Active': active,
            'Button--Loading': loading,
            'Button--Disable': buttonType === 'disable',
            'Button--Reset': buttonType === 'reset',
            'Button--Full-Width': buttonSize === 'full-width',
        },
        className
    );

    const childrenOrLoadingSpinner = loading ? <Spinner error={false} warn={false} light /> : children;

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                $buttonInner: grid(6);

                .Button {
                    border-radius: $border-radius;
                    border-width: 1px;
                    border-style: solid;
                    cursor: pointer;
                    font-size: grid(4);
                    @include text-midbold();
                    padding: grid(2) grid(4);
                    line-height: $buttonInner;

                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    color: $color-white;

                    + .Button {
                        margin-left: grid(2);
                    }

                    &--Block {
                        width: 100%;
                    }

                    > :global(img) {
                        width: $buttonInner;
                        height: $buttonInner;

                        & + * {
                            margin-left: 6px;
                        }
                    }

                    &--Small,
                    &--X-Small,
                    &--Medium,
                    &--Full-Width {
                        > :global(img) {
                            width: $icon-sm;
                            height: $icon-sm;

                            & + * {
                                margin-left: 4px;
                            }
                        }
                    }
                    &--Small,
                    &--Full-Width {
                        @include text-small();
                        padding: grid(2) grid(3);
                    }

                    &--X-Small {
                        @include text-xsmall();
                        padding: grid(1) grid(3);
                    }

                    &--Full-Width {
                        width: 100%;
                    }

                    &--Medium {
                        @include text-label();
                        padding: grid(3) grid(10);
                    }

                    &--Primary {
                        background-color: $isc-blue;
                        border-color: $isc-blue;

                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                        @media (min-width: $sm-max) {
                            &:hover,
                            &.active,
                            &:active {
                                background-color: $isc-blue;
                                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 5px 6px rgba(0, 0, 0, 0.08);
                            }
                        }
                    }

                    &--Quaternary {
                        background-color: $color-primary;
                        border-color: $color-primary;

                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                        @media (min-width: $sm-max) {
                            &:hover,
                            &.active,
                            &:active {
                                background-color: $color-primary-hover;
                                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 5px 6px rgba(0, 0, 0, 0.08);
                            }
                        }
                    }

                    &--Disable {
                        background-color: $color-grey47;
                        border-color: $color-grey47;

                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0);
                        @media (min-width: $sm-max) {
                            &:hover,
                            &.active,
                            &:active {
                                background-color: $color-grey47;
                                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0);
                            }
                        }
                    }

                    &--Redirect {
                        background-color: $color-grey60;
                        border-color: $color-grey60;

                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                        @media (min-width: $sm-max) {
                            &:hover,
                            &.active,
                            &:active {
                                background-color: $color-grey47;
                                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 5px 6px rgba(0, 0, 0, 0.08);
                            }
                        }
                    }

                    &--Secondary {
                        background-color: $color-white;
                        border-color: $color-secondary;
                        color: $color-copy;
                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

                        @media (prefers-color-scheme: dark) {
                            color: lighten($color-copy, 100%);
                            background-color: darken($color-white, 90%);
                        }

                        @media (min-width: $sm-max) {
                            &:hover,
                            &.active,
                            &:active {
                                background-color: $color-copy;
                                border-color: $color-copy;
                                color: $color-white;
                                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 5px 6px rgba(0, 0, 0, 0.08);
                            }
                        }
                    }

                    &--Tertiary {
                        background-color: $color-white;
                        border-color: $color-white;
                        color: $color-link;

                        @media (prefers-color-scheme: dark) {
                            color: lighten($color-link, 50%);
                            background-color: darken($color-white, 90%);
                        }

                        @media (min-width: $sm-max) {
                            &:hover {
                                background-color: $color-fade;
                                border-color: $color-fade;

                                @media (prefers-color-scheme: dark) {
                                    background-color: darken($color-fade, 80%);
                                }
                            }
                        }

                        &.Button--Active,
                        &.active,
                        &:active {
                            background-color: $color-copy;
                            color: $color-white;
                            border-color: $color-copy;

                            :global(img) {
                                @include icon-light;
                            }
                        }

                        :global(img) {
                            @include icon-primary;
                        }
                    }

                    &--Delete {
                        background-color: $color-error;
                        border-color: $color-error;

                        &:hover,
                        &.active,
                        &:active {
                            background-color: $color-error-hover;
                        }
                    }

                    &--Reset {
                        background-color: $color-white;
                        border-color: $color-error;
                        color: $color-error;
                        &:hover,
                        &.active,
                        &:active {
                            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 5px 6px rgba(0, 0, 0, 0.08);
                        }
                    }

                    &--Link {
                        border: none;
                        appearance: none;
                        background: none;
                        padding: 0;
                        color: $color-link;

                        @media (prefers-color-scheme: dark) {
                            color: lighten($color-link, 40%);
                        }
                    }

                    &--Warn {
                        color: $color-warn;
                        text-decoration: underline;
                    }

                    &--Loading {
                    }
                }

                :global(button) {
                    @extends .Button;
                }
            `}</style>
            {url ? (
                <a id={id} href={url} download={download} className={buttonClasses} onClick={action} target={openNewTab ? '_blank' : '_self'} rel={'noreferrer'}>
                    {childrenOrLoadingSpinner}
                </a>
            ) : (
                <button id={id} className={buttonClasses} onClick={action} disabled={disabled} type="button" {...rest}>
                    {childrenOrLoadingSpinner}
                </button>
            )}
        </>
    );
};

export default Button;
