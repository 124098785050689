import { IncomingIcon, OutgoingIcon } from '@assets/icons';
import classnames from 'classnames';
import React from 'react';

type MovementTagType = 'incoming' | 'outgoing';

interface Props {
    tagType?: MovementTagType;
    className?: any;
    sticky?: 'left' | 'right';
}

const MovementTag: React.FunctionComponent<Props> = ({ sticky, tagType, className }) => {
    const TagClasses = classnames(
        'Tag',
        {
            'Tag--Incoming': tagType === 'incoming',
            'Tag--Outgoing': tagType === 'outgoing',
            'Tag--Right': sticky === 'right',
        },
        className
    );
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .Tag {
                    display: flex;
                    align-items: center;
                    padding: grid(1.5) grid(4);
                    border-radius: 12px;
                    border: 1px solid transparent;
                    letter-spacing: 0.6px;
                    @include text-topic;
                    @include text-title;
                    $color-border-secondary: rgba($color-secondary, 0.8);

                    &--Incoming {
                        background: rgba($incoming-background, 0.2);
                        color: rgba($incoming-text, 0.8);
                    }

                    &--Outgoing {
                        background: rgba($outgoing-background, 0.2);
                        color: rgba($outgoing-text, 0.8);
                    }

                    &--Right {
                        position: absolute;
                        top: grid(5);
                        right: grid(3);
                    }
                }
            `}</style>
            <div className={TagClasses}>
                {tagType !== undefined && (
                    <>
                        {tagType === 'incoming' ? (
                            <>
                                <IncomingIcon className="icon-xxs" alt="Complete" />
                                &nbsp;&nbsp;INCOMING
                            </>
                        ) : (
                            <>
                                <OutgoingIcon className="icon-xxs" alt="Complete" />
                                &nbsp;&nbsp;OUTGOING
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
};
export default MovementTag;
