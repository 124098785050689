import { graphql } from 'relay-hooks';

// Fragment Definitions
// eslint-disable-next-line
const consignmentFragment = graphql`
    fragment ConsignmentEditConsignmentFragment on ConsignmentType {
        id
        number
        status
        movementDate
        movementTime
        species
        createdBy
        updatedAt
        heads
        pdfUrl
        basedOn
        movementID
        movementStatus
        numOfAddedDevices
        owner {
            address {
                line1
                postcode
                state
                town
            }
            name
            pic
        }
        origin {
            address {
                line1
                postcode
                state
                town
            }
            name
            pic
        }
        destination {
            address {
                line1
                postcode
                state
                town
            }
            name
            pic
        }
        consignee {
            address {
                line1
                postcode
                state
                town
            }
            name
            pic
        }

        forms {
            type
            serialNumber
        }

        answers {
            questionId
            index
            value
        }

        questions {
            ...QuestionFragment @relay(mask: false)
        }

        declaration {
            accept
            address {
                line1
                postcode
                state
                town
            }
            certificateNumber
            date
            email
            fullName
            phone
            signature
        }
    }
`;

export const ConsignmentEditQuery = graphql`
    query ConsignmentEditQuery($id: String!, $envdAccountId: String!) {
        consignment(id: $id, envdAccountId: $envdAccountId) {
            ...ConsignmentEditConsignmentFragment @relay(mask: false)
        }
    }
`;
