import React from 'react';

export interface ToggleSwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const ToggleSwitch: React.FunctionComponent<ToggleSwitchProps> = ({ disabled, onChange, ...checkboxProps }) => {
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';
                .switch {
                    position: relative;
                    display: inline-block;
                    width: 70px;
                    height: 35px;
                }
                .switch input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }
                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ccc;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                }
                .slider:before {
                    position: absolute;
                    content: '';
                    height: 28px;
                    width: 26px;
                    left: 4px;
                    bottom: 4px;
                    background-color: white;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                }
                input:checked + .slider {
                    background-color: $isc-blue;
                }
                input:focus + .slider {
                    box-shadow: 0 0 1px $isc-blue;
                }
                input:checked + .slider:before {
                    -webkit-transform: translateX(36px);
                    -ms-transform: translateX(36px);
                    transform: translateX(36px);
                }
                /* Rounded sliders */
                .slider.round {
                    border-radius: 34px;
                }
                .slider.round:before {
                    border-radius: 50%;
                }
            `}</style>
            <label className="switch">
                <input type="checkbox" {...checkboxProps} onChange={disabled ? undefined : onChange} disabled={disabled} />
                <span className="slider round"></span>
            </label>
        </>
    );
};
export default ToggleSwitch;
