import * as React from 'react';
const SvgReset = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
        <path
            fill="#CB3727"
            d="M10 2c2.33 0 4.42 1.02 5.88 2.62L13.5 7h6V1l-2.2 2.2C15.48 1.24 12.89 0 10 0 4.81 0 .55 3.95.05 9h2.02C2.56 5.06 5.93 2 10 2m0 18c5.19 0 9.45-3.95 9.95-9h-2.02c-.49 3.94-3.86 7-7.93 7-2.33 0-4.42-1.02-5.88-2.62L6.5 13h-6v6l2.2-2.2C4.52 18.76 7.11 20 10 20"
        />
    </svg>
);
export default SvgReset;

